/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
  padding-left: 0;
}

@media all and (min-width: 992px) {
  .sidebar .nav > li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

.offline-doc .page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #ffffff;
  position: relative;
}
.offline-doc .page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.offline-doc .page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.offline-doc .page-header footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.offline-doc .page-header .container {
  height: 100%;
  z-index: 1;
}
.offline-doc .page-header .category,
.offline-doc .page-header .description {
  color: rgba(255, 255, 255, 0.8);
}
.offline-doc .page-header.page-header-small {
  min-height: 60vh;
  max-height: 440px;
}
.offline-doc .page-header.page-header-mini {
  min-height: 40vh;
  max-height: 340px;
}
.offline-doc .page-header .title {
  margin-bottom: 15px;
}
.offline-doc .page-header .title + h4 {
  margin-top: 10px;
}
.offline-doc .page-header:after,
.offline-doc .page-header:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}
.offline-doc .page-header:before {
  background-color: rgba(0, 0, 0, 0.8);
}

.color_black {
  color: #000;
}

.color_black:focus {
  color: #000;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.loaderclass {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: 'transparent';
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
}

.loaderclassChild {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.float-4 {
	position:fixed;
	width:60px;
	height:60px;
	bottom:280px;
	right:40px;
	background-color:#e14eca;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.float-3 {
	position:fixed;
	width:60px;
	height:60px;
	bottom:200px;
	right:40px;
	background-color:#e14eca;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}


.float-2 {
	position:fixed;
	width:60px;
	height:60px;
	bottom:120px;
	right:40px;
	background-color:#e14eca;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.float {
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#e14eca;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.float-2:focus {
	color:#F5F5F5;
}

.float-3:focus {
	color:#F5F5F5;
}

.float-4.add-image {
	color:#F5F5F5;
}

.float:focus {
  color:#F5F5F5;
}

.float-3:hover {
	color:#F5F5F5;
}

.float-4:hover {
	color:#F5F5F5;
}

.float-2:hover {
	color:#F5F5F5;
}

.float:hover {
  color:#F5F5F5;
}

.my-float{
	margin-top:22px;  
}

.chat-container {
  width: 100%;
  max-width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.message-left, .message-right {
  border-radius: 15px 15px 0px 0px;
  margin: 10px 10px 0px 10px;
  max-width: 300px;
  word-wrap: break-word;
}

.message-right > div {
  padding: 10px 15px 0px 15px;
}

.message-left > div {
  padding: 10px 15px 0px 15px;
}

.message-left {
  background-color: #202c33;
  color: #fff;
  align-self: flex-start;
}

.message-right {
  background-color: #005c4b;
  color: #fff;
  align-self: flex-end;
  text-align: right;
}

.message-left p, .message-right p {
  margin: 0;
}

.type-message {
  background-color: #4b4646;
  padding: 0px 5px;
  max-width: 300px;
  margin-top: 5px !important;
}

.cardData {
  padding: 2em;
}

.cardData > h1 {
  margin-top: 0.5em;
}

.cardData > h4 > i {
  margin-right: 10px;
}

.m_title {
  text-transform: lowercase
}

.m_title::first-letter {
  text-transform: uppercase
}

.month_summary {
  margin: 2rem 0rem;
  display: flex;
  justify-content: space-evenly;
}

.month_summary_arrow {
  font-size: 1.5rem;
}

.year_month_summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="file"] {
  position: relative;
}

input[type="file"]::file-selector-button {
  width: 210px;
  color: transparent;
}

/* Faked label styles and icon */
input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 40px;
  color: #0964b0;
  content: "Sube uno o mas archivos";
}

/* ------- From Step 1 ------- */

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}

#webpack-dev-server-client-overlay {
  display: none;
}

.headerchat {
  display: flex;
  padding: 20px 60px;
}

.herderphone {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.modal-charts .modal-dialog {
  max-width: 80% !important;
  max-height: 80% !important;
  transform: none !important;
}


.modal-charts > .modal-dialog > .modal-content {
  background-color: #25273a;
  border: 2px solid #f5f5f5;
}

.modal-charts > .modal-dialog > .modal-content > .modal-header > h5 > h2 {
  color: #f5f5f5 !important;
}

.title-card-dashboard {
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-wrap: nowrap;
}




.file-upload {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1e83ec;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #1e83ec;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #0c3d72;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  text-align: center;
}

.file-upload-input {
  position: absolute !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #1e83ec;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #1e81ec91;
  color: #FFF;
  border: 4px dashed #ffffff;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #1e83ec;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  margin-top: 15px;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all .2s ease;
}

.add-image {
  width: 200px;
  margin: 0;
  margin-top: 15px;
  color: #fff;
  background: #344675;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #344675;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.gray {
  color: #6c757d;
}

nav[aria-label="pagination"] {
  width: 100% !important;
}

.width100p {
  width: 100% !important;
}

.listItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.badge-override {
  color: #FFF;
  background-color: #5e72e4;
}

.separator {
  margin-top: 0rem;
  margin-bottom: 0rem;
  width: 80%;
  background-color: rgb(10, 163, 187);
  height: 2px;
}

.paddinleft0 {
  padding-left: 0px;
}

.paddingright0 {
  padding-right: 0px;
}

.paddinleftrifht0 {
  padding-left: 0px;
  padding-right: 0px;
}

.card-dashboard-nav {
  box-shadow: none !important;
  border-radius: 0px;
  padding: 1rem 0rem;
  max-height: 130px;
}

.card-dashboard-nav > div {
  border-left: 2px solid rgb(10, 163, 187) !important;
}

.card-dashboard-nav-corner {
  box-shadow: none !important;
  border-radius: 10px 0px 0px 0px;
  padding: 1rem 0rem;
  max-height: 130px;
}
